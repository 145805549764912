import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Context
import { UserContext } from "../../store/UserContext";
import agroApi from "../../services/agroApi";

// Layouts
import Tracking from "../../layouts/Tracking";
import Replay from "../../layouts/Replay";
import Dashboard from "../../layouts/Dashboard";
import Report from "../../layouts/Reports";
import UsersLayout from "../../layouts/Users";

// Icons
import ExitToApp from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";
import Storefront from "@material-ui/icons/Storefront";
import VpnKey from "@material-ui/icons/VpnKey";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LayersIcon from "@material-ui/icons/Layers";
import { TruckMonsterIcon, AlarmIcon, ReportIcon } from "../../assets/icons";
import PlayCircleOutlineSharpIcon from "@material-ui/icons/PlayCircleOutlineSharp";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

// Logo
import logo3 from "../../assets/logo4.png";
import version from "../../version";

const drawerWidth = 240;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 8,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

/*
const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
*/

export default function ApplicationBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { User } = useContext(UserContext);
  const [pass, setPass] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const history = useHistory();
  const [ActiveLayout, setActiveLayout] = React.useState(1);

  const [msg, setMsg] = React.useState({
    open: false,
    txt: "",
    severity: "",
  });

  const [password1, setPassword1] = React.useState(null);
  const [password2, setPassword2] = React.useState(null);

  const [anchorCustomer, setAnchorCustomer] = React.useState(null);
  const isMenuCustomerOpen = Boolean(anchorCustomer);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuCustomerClose = () => {
    setAnchorCustomer(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuCustomerOpen = (event) => {
    setAnchorCustomer(event.currentTarget);
  };

  const handleLogout = () => {
    history.push("/");
  };

  const handlePasswordOpen = () => {
    handleMenuClose();
    setPass(true);
  };

  async function changePassword() {
    await agroApi.post("/users/" + User.user_id + "/updatepass", { user_pass: password1 }, { headers: { token: User.token } }).catch((error) => {
      if (error.response.status === 401) {
        history.push("/");
      }
    });
  }

  const handlePasswordClose = (ev) => {
    const btn = ev.currentTarget.value;

    if (btn && btn === "btnOK") {
      if (!password1 || !password2 || password1 !== password2) {
        setMsg({
          open: true,
          txt: "main.passworderror",
          severity: "error",
        });
        return;
      }
      changePassword();

      setMsg({
        open: true,
        txt: "main.passwordok",
        severity: "success",
      });
    }

    handleMenuClose();
    setPass(false);
  };

  const handleMenuCustomerClick = (ev) => {
    setAnchorCustomer(null);

    const customer_id = ev.currentTarget.value;
    //console.log(customer_id);
    for (let i = 0; i < User.customers.length; i++) {
      if (parseInt(User.customers[i].customer_id) === customer_id) {
        User.ActiveCustomer = i;
        break;
      }
    }
  };

  const handleMsg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsg({
      open: false,
    });
  };

  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuId} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMenuOpen} onClose={handleMenuClose}>
      <MenuItem onClick={handlePasswordOpen}>
        <ListItemIcon>
          <VpnKey fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t("main.changepassword")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t("main.logout")} />
      </MenuItem>
    </Menu>
  );

  const menuIdCustomer = "customer-menu";
  const renderMenuCustomer = (
    <Menu anchorEl={anchorCustomer} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuIdCustomer} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMenuCustomerOpen} onClose={handleMenuCustomerClose}>
      {User.customers.map((e) => (
        <MenuItem key={`${e.customer_id}`} value={e.customer_id} onClick={handleMenuCustomerClick}>
          {e.customer_name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar open={msg.open} autoHideDuration={3000} onClose={handleMsg} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={handleMsg} severity={msg.severity}>
          {t(msg.txt)}
        </Alert>
      </Snackbar>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <div>
            <img src={logo3} alt="Agrothings" width="160px" />
          </div>
          <div>{version}</div>
          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
            {User.customers[User.ActiveCustomer].customer_name}
          </Typography>
          <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit" onClick={handleProfileMenuCustomerOpen} aria-controls={menuIdCustomer}>
            <Storefront />
          </IconButton>
          <IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={User.alarms} color="secondary" overlap="rectangular">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit" onClick={handleProfileMenuOpen} aria-controls={menuId}>
            <Person />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMenuCustomer}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <Tooltip title={t("main.dashboard")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(1);
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.dashboard")} />
          </ListItem>
          <ListItem button>
            <Tooltip title={t("main.tracking")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(2);
                }}
              >
                <TruckMonsterIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.tracking")} />
          </ListItem>
          <ListItem button>
            <Tooltip title={t("main.alarms")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(3);
                }}
              >
                <AlarmIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.alarms")} />
          </ListItem>
          <ListItem button>
            <Tooltip title={t("main.replay")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(4);
                }}
              >
                <PlayCircleOutlineSharpIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.replay")} />
          </ListItem>
          <ListItem button>
            <Tooltip title={t("main.reports")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(5);
                }}
              >
                <ReportIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.reports")} />
          </ListItem>
          <ListItem button>
            <Tooltip title={t("main.integrations")} placement="right">
              <ListItemIcon
                onClick={() => {
                  setActiveLayout(6);
                }}
              >
                <LayersIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("main.integrations")} />
          </ListItem>
          {User.user_rights < 501 ? (
            <ListItem button>
              <Tooltip title={t("main.usuarios")} placement="right">
                <ListItemIcon
                  onClick={() => {
                    setActiveLayout(7);
                  }}
                >
                  <PeopleAltIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={t("main.usuarios")} />
            </ListItem>
          ) : (
            ""
          )}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {ActiveLayout === 1 ? <Dashboard /> : ActiveLayout === 2 ? <Tracking /> : ActiveLayout === 3 ? "Alarms" : ActiveLayout === 4 ? <Replay /> : ActiveLayout === 5 ? <Report /> : ActiveLayout === 7 ? <UsersLayout /> : "Integrations"}
      </main>
      <Dialog open={pass} onClose={handlePasswordClose} aria-labelledby="form-dialog-title" maxWidth="xs">
        <DialogTitle id="form-dialog-title">{t("main.changepassword")}</DialogTitle>
        <DialogContent>
          <TextField variant="outlined" margin="dense" required fullWidth name="password" label={t("main.typepassword")} type="password" id="pass" onChange={(event) => setPassword1(event.target.value)} />
          <TextField variant="outlined" margin="dense" required fullWidth name="password" label={t("main.retypepassword")} type="password" id="pass1" onChange={(event) => setPassword2(event.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button value="btnOK" onClick={handlePasswordClose} color="primary">
            Ok
          </Button>
          <Button value="btnCANCEL" onClick={handlePasswordClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      )
    </div>
  );
}
