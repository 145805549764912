import React, { useState, useEffect, useContext } from "react";
import Sound from "react-sound";
import { UserContext } from "../store/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import agroApi from "../services/agroApi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import Badge from "@material-ui/core/Badge";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningIcon from "@material-ui/icons/Warning";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import { Tooltip } from "@material-ui/core";

import { red, blue, amber } from "@material-ui/core/colors";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "80vh",
    overflow: "scroll",
  },
}));

export default function EventsGrid() {
  const { User } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const playFile = "/sounds/AlertaTelemetria.mp3";
  const classes = useStyles();
  const history = useHistory();
  const [tabValue, setTabValue] = React.useState(0);
  const [total1, setTotal1] = React.useState(0);
  const [total2, setTotal2] = React.useState(0);
  const [reload, setReload] = React.useState(0);
  const { t } = useTranslation();

  function saveEventStatus(event_id, event_status) {
    agroApi.put("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/events/" + event_id, { event_status: event_status, user_id: User.user_id }, { headers: { token: User.token } }).then(() => {
      setPlayStatus(Sound.status.STOPPED);
      setReload(reload + 1);
    });
  }

  useEffect(() => {
    agroApi.get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/events2", { headers: { token: User.token } }).then((data) => {
      console.log(data);
      for (let i = 0; i < data.data.rows.length; i++) {
        if (data.data.rows[i].eventtype_sound > 0) {
          setPlayStatus(Sound.status.PLAYING);
          break;
        }
      }

      setEvents(data.data.rows);
      setTotal1(data.data.sumary[0]);
      setTotal2(data.data.sumary[1]);
    });
    const timeoutId = setTimeout(() => {
      setReload(reload + 1);
    }, 30000);
    return () => clearTimeout(timeoutId);
  }, [User.ActiveCustomer, User.customers, User.token, history, reload]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{ height: "100%", width: "100%hv" }}>
      <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon label tabs example">
        <Tab
          icon={
            <Badge
              color="secondary"
              badgeContent={total1}
              max={999}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="rectangular"
            >
              <ReportProblemIcon />
            </Badge>
          }
          label="Alertas"
        />
        <Tab
          icon={
            <Badge
              color="secondary"
              badgeContent={total2}
              max={999}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="rectangular"
            >
              <PhoneIphoneIcon />
            </Badge>
          }
          label="Apontamentos"
        />
      </Tabs>
      <List className={classes.root} dense={true}>
        {events !== undefined &&
          events.map(
            (event, idx) =>
              event.eventtype_type === tabValue && (
                <ListItem key={`event-${event.event_id}`} divider={idx !== events.length - 1}>
                  <Icon style={event.eventtype_severity === 1 ? { color: blue[500] } : event.eventtype_severity === 2 ? { color: amber[300] } : event.eventtype_severity === 3 ? { color: red[300] } : { color: red[800] }}>
                    {event.eventtype_severity === 1 || event.eventtype_severity === 2 ? <ErrorOutlineOutlinedIcon /> : <WarningIcon />}
                  </Icon>
                  <ListItemText
                    style={{ marginLeft: 10 }}
                    primary={event.device_id + " - " + event.device_name + ", " + event.ruletype_name + (event.driver_name !== "" ? " (" + event.driver_name + ")" : "")}
                    secondary={
                      t(event.eventtype_name) +
                      " (" +
                      event.event_count +
                      ") " +
                      moment(event.event_datetime)
                        .utc(0)
                        .add(User.customers[User.ActiveCustomer].customer_tz + User.customers[User.ActiveCustomer].customer_dls, "hours")
                        .format("HH:mm DD/MM/YYYY")
                    }
                  ></ListItemText>
                  <IconButton
                    onClick={() => {
                      saveEventStatus(`${event.event_id}`, 2);
                    }}
                  >
                    <Tooltip placement="left" title="Apaga o aviso">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </ListItem>
              )
          )}
      </List>
      <Sound url={playFile} playStatus={playStatus} volume={100} loop={false} playbackRate={1} autoLoad={true} onFinishedPlaying={() => setPlayStatus(Sound.status.STOPPED)} />
    </div>
  );
}
