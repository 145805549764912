import React, { useState, useEffect, useContext, useRef } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import MainGrid from "../components/Maingrid";
import { UserContext } from "../store/UserContext";

import agroApi from "../services/agroApi";

import { Map, Marker, Popup, TileLayer, Polygon, Tooltip, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import EventsGrid from "../components/EventsGrid";
import { LayersControl } from "react-leaflet";

export const icon_1 = new L.Icon({
  iconUrl: "/images/1.svg",
  iconRetinaUrl: "/images/1.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [90, 100],
});

export const icon_2 = new L.Icon({
  iconUrl: "/images/2.svg",
  iconRetinaUrl: "/images/2.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_3 = new L.Icon({
  iconUrl: "/images/3.svg",
  iconRetinaUrl: "/images/3.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_4 = new L.Icon({
  iconUrl: "/images/4.svg",
  iconRetinaUrl: "/images/4.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_5 = new L.Icon({
  iconUrl: "/images/5.svg",
  iconRetinaUrl: "/images/5.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 55],
});

export const icon_6 = new L.Icon({
  iconUrl: "/images/6.svg",
  //iconRetinaUrl: "/images/6.svg",
  //iconAnchor: [5, 55],
  //popupAnchor: [10, -44],
  iconSize: [25, 45],
});

export const icon_7 = new L.Icon({
  iconUrl: "/images/7.svg",
  iconRetinaUrl: "/images/7.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_8 = new L.Icon({
  iconUrl: "/images/8.svg",
  iconRetinaUrl: "/images/8.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_9 = new L.Icon({
  iconUrl: "/images/9.svg",
  iconRetinaUrl: "/images/9.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_10 = new L.Icon({
  iconUrl: "/images/10.svg",
  //iconRetinaUrl: "/images/10.svg",
  //iconAnchor: [5, 55],
  //popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_11 = new L.Icon({
  iconUrl: "/images/11.svg",
  iconRetinaUrl: "/images/11.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_12 = new L.Icon({
  iconUrl: "/images/12.svg",
  iconRetinaUrl: "/images/12.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [35, 70],
});

export const icon_15 = new L.Icon({
  iconUrl: "/images/15.svg",
  iconRetinaUrl: "/images/6.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 45],
});

export default function Tracking() {
  const mapRef = useRef();
  const { User } = useContext(UserContext);
  const [vehicles, setVehicles] = useState([]);
  const [geojson, setGeojson] = useState([]);
  const [pumps, setPumps] = useState([]);
  const [reloadvehicles, setReloadVehicles] = useState(0);

  useEffect(() => {
    //setReloadVehicles(reloadvehicles + 1);
    setTimeout(() => {
      agroApi
        .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/geojsonn", {
          headers: { token: User.token },
        })
        .then((data) => {
          setGeojson(data.data);
        })
        .catch((error) => {});
    }, 500);
    setTimeout(() => {
      agroApi
        .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/maingrid/pumps", {
          headers: { token: User.token },
        })
        .then((data) => {
          setPumps(data.data);
        })
        .catch((error) => {});
    }, 500);
  }, [User.ActiveCustomer, User.customers, User.token]);

  useEffect(() => {
    agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/maingrid/cblatlondata", {
        headers: { token: User.token },
      })
      .then((data) => {
        setVehicles(data.data);
      });
    const timeoutId = setInterval(() => {
      setReloadVehicles(reloadvehicles + 1);
    }, 30000);
    return () => clearInterval(timeoutId);
  }, [reloadvehicles, User.ActiveCustomer, User.customers, User.token]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={8} lg={7} xl={8}>
        <Map style={{ height: "43vh", width: "10hv" }} center={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} zoom={10} ref={mapRef} id="mapid" maxZoom={20} enableHighAccuracy>
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          <LayersControl position="topright">
            <LayersControl.Overlay checked name="Sede">
              <Marker position={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} key={`sede001`} color="#fff">
                <Popup>{User.customers[User.ActiveCustomer].customer_name}</Popup>
              </Marker>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Maquinas">
              <FeatureGroup>
                <MarkerClusterGroup disableClusteringAtZoom={16} removeOutsideVisibleBounds={true}>
                  {vehicles.map((e) => (
                    <Marker
                      key={`vehicle-${e.device_id}`}
                      position={[e.latitude, e.longitude]}
                      Title={e.device_name}
                      icon={
                        e.devicetype_id === "1"
                          ? icon_1
                          : e.devicetype_id === "2"
                          ? icon_2
                          : e.devicetype_id === "3"
                          ? icon_3
                          : e.devicetype_id === "4"
                          ? icon_4
                          : e.devicetype_id === "5"
                          ? icon_5
                          : e.devicetype_id === "6"
                          ? icon_6
                          : e.devicetype_id === "7"
                          ? icon_7
                          : e.devicetype_id === "8"
                          ? icon_8
                          : e.devicetype_id === "9"
                          ? icon_9
                          : e.devicetype_id === "10"
                          ? icon_10
                          : e.devicetype_id === "11"
                          ? icon_10
                          : e.devicetype_id === "14"
                          ? icon_4
                          : icon_12
                      }
                    >
                      <Popup>
                        <span>{e.device_name}</span>
                      </Popup>
                      <Tooltip sticky="true" opacity="0.7" className="fences">
                        {e.device_name}
                      </Tooltip>
                    </Marker>
                  ))}
                </MarkerClusterGroup>
              </FeatureGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Talhões">
              <FeatureGroup>
                {geojson.map((p) => (
                  <Polygon key={`poly-${p.fence_id}`} positions={p.fence_vertex} color={p.crop_color}>
                    <Popup>{p.fence_name + " " + p.crop_name}</Popup>
                    <Tooltip sticky="true" opacity="0.7" className="fences">
                      {p.fence_name + " " + p.crop_name}
                    </Tooltip>
                  </Polygon>
                ))}
              </FeatureGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Casas captação / Poços">
              <FeatureGroup>
                {pumps.map((e) => (
                  <Marker key={`pump-${e.device_id}`} position={[e.device_latitude, e.device_longitude]} Title="bbb" icon={e.devicetype_id === 6 ? icon_6 : icon_15}>
                    <Popup>
                      <span>{e.device_name}</span>
                    </Popup>
                    <Tooltip sticky="true" opacity="0.7" className="fences">
                      {e.device_name}
                    </Tooltip>
                  </Marker>
                ))}
              </FeatureGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </Map>
        <MainGrid mapref={mapRef} />
      </Grid>
      <Grid item xs={12} sm={4} lg={5} xl={4}>
        <Paper>
          <EventsGrid mapref={mapRef} />
        </Paper>
      </Grid>
    </Grid>
  );
}
