import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useTranslation } from "react-i18next";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import agroApi from "../services/agroApi";
import { UserContext } from "../store/UserContext";

import { Map, Marker, Popup, TileLayer, Polygon, Tooltip, CircleMarker } from "react-leaflet";
import Polyline from "react-leaflet-arrowheads";

var moment = require("moment");
var gridFilter = "";

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={0}>{children}</Box>}</div>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxHeight: 350,
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ReplayGrid() {
  const classes = useStyles();
  const [panelIdx, setPanelIdx] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const { User } = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [rasterData, setRasterData] = useState([]);
  const [markerData, setMarkerData] = useState([]);
  const [reportDateStart, setReportDateStart] = useState(moment().format("YYYY-MM-DD") + " 00:00");
  const [reportDateEnd, setReportDateEnd] = useState(moment().format("YYYY-MM-DD") + " 23:59");
  const [devices, setDevices] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [fences, setFences] = useState([]);
  const mapRef = useRef();
  const [msgAlerta, setMsgAlerta] = useState(false);

  const maingrid_elements = User.customers[User.ActiveCustomer].maingrid_elements;

  /**
   *    CARREGAS OS DEVICES - DEFAULT MAQUINAS
   */
  useEffect(() => {
    agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/replaydevices", {
        headers: { token: User.token },
        params: { type: panelIdx },
      })
      .then((data) => {
        setRowData(data.data);
      })
      .catch((error) => {
        //console.log(error.response);
        if (error.response.status === 401) {
          history.push("/");
        }
      });
    gridFilter = "";
  }, [User.ActiveCustomer, User.customers, User.token, history, panelIdx]);

  /**
   *    LOAD REPLAY DATA
   */
  useEffect(() => {
    if (devices) {
      if (devices.length > 0) {
        agroApi
          .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/replaydatamn", {
            headers: { token: User.token },
            params: {
              startdate: reportDateStart,
              enddate: reportDateEnd,
              ids: devices,
            },
          })
          .then((data) => {
            console.log(data);
            setRasterData(data.data.raster);
            setMarkerData(data.data.markers);
            setDevices(null);
            setBackDrop(false);
            const { current = {} } = mapRef;
            const { leafletElement: map } = current;
            map.fitBounds(data.data.bounds);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              history.push("/");
            }
            setBackDrop(false);
            setDevices(null);
            //console.log(error.response);
          });
      }
    }
  }, [User.ActiveCustomer, User.customers, User.token, devices, history, reportDateEnd, reportDateStart]);

  /**
   *    CARREGA OS TALHOES
   */
  useEffect(() => {
    agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/geojsonn", {
        headers: { token: User.token },
      })
      .then((data) => {
        setFences(data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
        setBackDrop(false);
      });
  }, [User.ActiveCustomer, User.customers, User.token, history]);

  const handleChangeTab = (event, newValue) => {
    setPanelIdx(newValue);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const startDateChange = (event) => {
    setReportDateStart(event.target.value);
  };

  const endDateChange = (event) => {
    setReportDateEnd(event.target.value);
  };

  const loadRasterData = () => {
    //if (reportDateEnd > reportDateStart) {
    var ids = "";
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      selectedRows.forEach((element) => {
        if (ids.length > 0) ids += "," + element.device_id;
        else ids = element.device_id;
      });
      setBackDrop(true);
      setDevices("(" + ids + ")");
    }
    /*} else {
      setMsgAlerta(true);
    }*/
  };

  const gridFilterChanged = (event) => {
    gridFilter = event.target.value;
    gridApi.onFilterChanged();
  };

  const isExternalFilterPresent = () => {
    return gridFilter.length !== 0;
  };

  const doesExternalFilterPass = (node) => {
    if (node.data) {
      return node.data.device_name.toLowerCase().includes(gridFilter.toLowerCase());
    }
    return true;
  };

  const handleMsgAlertaClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsgAlerta(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={10} sm={8} lg={3} xl={3}>
          <AppBar position="static" color="default">
            <Tabs value={panelIdx} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
              {maingrid_elements.indexOf("A") > -1 && <Tab label={t("maingrid.machinery")} />}
              {maingrid_elements.indexOf("C") > -1 && <Tab label={t("maingrid.pivots")} />}
              {maingrid_elements.indexOf("D") > -1 && <Tab label={t("maingrid.utility")} />}
              {maingrid_elements.indexOf("F") > -1 && <Tab label={t("replay.talhoes")} />}
            </Tabs>
          </AppBar>
          {maingrid_elements.indexOf("A") > -1 && <TabPanel value={panelIdx} index={0}></TabPanel>}
          {maingrid_elements.indexOf("C") > -1 && <TabPanel value={panelIdx} index={2}></TabPanel>}
          {maingrid_elements.indexOf("D") > -1 && <TabPanel value={panelIdx} index={3}></TabPanel>}
          {maingrid_elements.indexOf("F") > -1 && <TabPanel value={panelIdx} index={4}></TabPanel>}
          <Box display="flex" width={"100%"} alignItems="center" justifyContent="center">
            <InputBase className={classes.input} placeholder="Procurar" inputProps={{ "aria-label": "search google maps" }} fullWidth={true} onChange={(evt) => gridFilterChanged(evt)} />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Box>
          <div className="ag-theme-balham" style={{ height: "40vh", width: "100hv" }}>
            <AgGridReact
              onGridReady={onGridReady}
              rowSelection="multiple"
              animateRows
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              columnDefs={[
                {
                  headerName: t("maingrid.id"),
                  field: "device_id",
                  width: 100,
                  minWidth: 80,
                  maxWidth: 120,
                  checkboxSelection: true,
                  //headerCheckboxSelection: true,
                },
                {
                  headerName: t("maingrid.name"),
                  field: "device_name",
                  width: 300,
                  minWidth: 300,
                  maxWidth: 400,
                },
              ]}
              rowData={rowData}
              modules={AllCommunityModules}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
            />
            <Paper>
              <Box display="flex" width={"100%"} alignItems="center" justifyContent="center">
                <TextField
                  onChange={(evt) => startDateChange(evt)}
                  id="rptDateStart"
                  label={t("replay.startdate")}
                  type="datetime-local"
                  defaultValue={reportDateStart}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                  onChange={(evt) => endDateChange(evt)}
                  id="rptDateEnd"
                  label={t("replay.enddate")}
                  type="datetime-local"
                  defaultValue={reportDateEnd}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box display="flex" width={"100%"} height={70} alignItems="center" justifyContent="center">
                <Button variant="contained" color="primary" onClick={loadRasterData}>
                  {t("replay.loadreplay")}
                </Button>
              </Box>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={9} xl={9}>
          <div id="mapid">
            <div style={{ position: "absolute", zIndex: "999" }}>bottom</div>
            <div style={{ position: "absolute", zIndex: "998" }}>top</div>
            <Map style={{ height: "92vh", width: "100%" }} center={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} zoom={18} maxZoom={30} id="mapid" ref={mapRef}>
              <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
              <Marker position={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} color="#fff">
                <Popup>{User.customers[User.ActiveCustomer].customer_name}</Popup>
              </Marker>
              {fences.map((p) => (
                <Polygon key={`poly-${p.fence_id}`} positions={p.fence_vertex} color={p.crop_color}>
                  <Popup>{p.fence_name + " " + p.crop_name}</Popup>
                  <Tooltip sticky="true" opacity="0.7" className="fences">
                    {p.fence_name + " " + p.crop_name}
                  </Tooltip>
                </Polygon>
              ))}
              {rasterData.map((gl) => (
                <Polyline
                  key={`gline-${gl.id}`}
                  positions={[
                    [gl.flat, gl.flon],
                    [gl.tlat, gl.tlon],
                  ]}
                  arrowheads={{ size: "8px" }}
                  smoothFactor={2}
                  color={gl.color}
                >
                  <Tooltip>{gl.txt}</Tooltip>
                </Polyline>
              ))}
              {markerData.map((gl) => (
                <CircleMarker key={`cm-${gl.id}`} center={[gl.lat, gl.lon]} color={gl.color} fillColor={gl.color} opacity={200} radius={18} fillOpacity={100}>
                  <Tooltip sticky={false} opacity="0.7" className="fences" permanent={true} offset={[0, 0]}>
                    {`${gl.txt}`}
                  </Tooltip>
                </CircleMarker>
              ))}
            </Map>
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <Snackbar open={msgAlerta} autoHideDuration={4000} onClose={handleMsgAlertaClose}>
        <Alert onClose={handleMsgAlertaClose} severity="error">
          {t("replay.erroperiodo")}
        </Alert>
      </Snackbar>
    </div>
  );
}
