import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import { UserContext } from "../store/UserContext";
import agroApi from "../services/agroApi";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import ButtonGroup from "@material-ui/core/ButtonGroup";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "40ch",
      marginTop: theme.spacing(1),
    },
    "& .NativeSelect-root": {
      margin: theme.spacing(1),
      width: "30ch",
      marginTop: theme.spacing(1),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

export default function UsersGrid() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { User } = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [newUser, setNewUser] = React.useState("");
  const history = useHistory();
  const [qrCodeLiberacao, setQrCodeLiberacao] = useState("");
  const [userId, setUserId] = useState(0);
  const [reload, setReload] = React.useState(0);

  function ChangePhoneStatus(userid, status) {
    agroApi
      .post(
        "/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones/status",
        { user_id: userid, status: status },
        {
          headers: { token: User.token },
        }
      )
      .then(() => {
        setReload(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
      });
  }

  useEffect(() => {
    function LoadData() {
      agroApi
        .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones", {
          headers: { token: User.token },
        })
        .then((data) => {
          setRowData(data.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            history.push("/");
          }
        });
    }
    LoadData();
    setNewUser("");
    setQrCodeLiberacao("");
    setReload(0);
  }, [User.ActiveCustomer, User.customers, User.token, history, reload]);

  const onRowSelected = (event) => {
    if (event.node.selected) {
      setUserId(event.node.data.user_id);
      if (event.node.data.user_pass == null) {
        setQrCodeLiberacao(event.node.data.user_email);
      } else {
        setQrCodeLiberacao("");
      }
    }
  };

  const handleSubmit = (event) => {
    agroApi
      .post("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones", { phone_description: newUser }, { headers: { token: User.token } })
      .then((resp) => {
        setReload(1);
        setNewUser("");
        setQrCodeLiberacao("");
        setTimeout(() => {
          setReload(1);
        }, 1000);
      })
      .catch((err) => {});
  };

  const handleBlockNuttonClick = (event) => {
    if (userId !== 0) {
      ChangePhoneStatus(userId, 0);
      setNewUser("");
      setQrCodeLiberacao("");
      setUserId(0);
    }
  };

  const handleReleaseButtonClick = (event) => {
    if (userId !== 0) {
      ChangePhoneStatus(userId, 1);
      setNewUser("");
      setQrCodeLiberacao("");
      setUserId(0);
    }
  };

  return (
    <div style={{ height: "80vh", width: "100hv" }}>
      <div className={classes.root} style={{ height: "10vh", width: "100hv" }}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <br></br>
          <TextField id="standard-basic" label="Descrição" onChange={(event) => setNewUser(event.target.value)} value={newUser} />
          <Button type="submit" variant="contained" color="secondary" className={classes.button} startIcon={<PhoneAndroidIcon />}>
            Cadastrar
          </Button>
        </form>
      </div>
      <div className="ag-theme-balham" style={{ height: "30vh", width: "100hv" }}>
        <ButtonGroup variant="contained" size="small" aria-label="outlined primary button group">
          <Button startIcon={<PhonelinkEraseIcon />} onClick={(e) => handleBlockNuttonClick(e)}>
            Bloquear
          </Button>
          <Button startIcon={<PhoneAndroidIcon />} onClick={(e) => handleReleaseButtonClick(e)}>
            Liberar
          </Button>
        </ButtonGroup>
        <AgGridReact
          //ref="agGrid"
          rowSelection="single"
          animateRows
          rowData={rowData}
          modules={AllCommunityModules}
          //getRowNodeId={this.getRowNodeId}
          onRowSelected={onRowSelected}
          //onGridReady={this.onGridReady}*/
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          columnDefs={[
            { headerName: t("phonegrid.id"), field: "user_id", width: 80, minWidth: 50, maxWidth: 120 },
            { headerName: t("phonegrid.name"), field: "user_name", width: 240, minWidth: 240, maxWidth: 340 },
            { headerName: t("phonegrid.email"), field: "user_email", width: 240, minWidth: 240, maxWidth: 240 },
            {
              headerName: t("phonegrid.status"),
              field: "user_status",
              width: 100,
              minWidth: 100,
              maxWidth: 120,
              cellRenderer: (data) => {
                if (data.value === 0) {
                  return "Bloqueado";
                } else {
                  return "Liberado";
                }
              },
            },
            {
              headerName: t("phonegrid.state"),
              field: "user_pass",
              width: 120,
              minWidth: 120,
              maxWidth: 120,
              cellRenderer: (data) => {
                if (data.value != null) {
                  return "Sim";
                } else {
                  return "Não";
                }
              },
            },
            {
              headerName: t("phonegrid.add"),
              field: "user_add",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).add(-3).format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
            {
              headerName: t("phonegrid.lastlogin"),
              field: "user_lastlogin",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).utc(0).format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
            {
              headerName: "",
              field: "user_pass",
              width: 50,
              minWidth: 50,
              maxWidth: 50,
              cellRenderer: (data) => {
                if (data.value != null) {
                  return "";
                } else {
                  var element = document.createElement("span");
                  var imageElement = document.createElement("img");
                  imageElement.src = "/images/qrcode.png";
                  element.appendChild(imageElement);
                  return element;
                }
              },
            },
          ]}
        ></AgGridReact>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40vh" }}>{qrCodeLiberacao !== "" ? <QRCode value={qrCodeLiberacao}></QRCode> : ""}</div>
    </div>
  );
}
